<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row>
        <b-col class="col-1" sm="2">
        </b-col>
        <b-col class="col-10 text-center" sm="8"> 
          <p class="main-title">MADE BY WINE LOVERS</p>
          <p class="main-title">FOR WINE LOVERS</p>
          <hr>
          <p class="w-title">We are a group of people passionate about wine. <p>
          <p class="w-text">Our goal is to improve the digital wine experience in an open non-commercial way; we called this project ... <b>WyneLover</b>.</p>
          <p class="w-text">In a first phase we are developing a trilogy of online applications: WyneMaker, WyneCellar & WyneTaster.</p>
          <b-row>
            <b-col sm="3">
              <p class="w-text text-left"><a style="color: rgb(112,112,112)" href="http://www.wynemaker.com"><b>www.wynemaker.com</b></a></p>
            </b-col>
            <b-col>
              <p class="w-text text-left">for wine producers to share information on their winery and wines</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <p class="w-text text-left"><a style="color: rgb(112,112,112)" href="http://www.wynecellar.com"><b>www.wynecellar.com</b></a></p>
            </b-col>
            <b-col>
              <p class="w-text text-left">for wine lovers to manage their wine cellar</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <p class="w-text text-left"><a style="color: rgb(112,112,112)" href="http://www.wynetaster.com"><b>www.wynetaster.com</b></a></p>
            </b-col>
            <b-col>
              <p class="w-text text-left">to organize or attend a wine tasting events and manage your wine tasting notes</p>
            </b-col>
          </b-row>
          <p class="w-text">Once the WyneDatabase is sufficiently populated by winemakers using the WyneMaker application, we intend to share it with the rest of the world through API.</p>
          <hr>
          <p class="w-title">How can you help us?</p>
          <p class="w-subtitle">Active support.</p>
          <p class="w-text">To finish and promote the project, we are looking for passionate volunteers with a great variety of skills: marketing skills, wine knowledge from a specific country, wine knowledge from all kinds, web design skills, ui/ux skills, language skills & communication skills in different countries, contacts with wine producers, wine producer associations, wine magazines … to join our team of volunteers to accomplish this common goal; provide great digital tools that will greatly support your journey as wine lover.</p>
          <p class="w-text">Interested to become a WyneBassador?</p>
          <p class="w-text">Drop a mail to <b>admin@wynelover.com</b> with your specific skill that could be useful for this project.</p>
          
          <p class="w-subtitle">Passive support.</p>
          <p class="w-text">We intent to keep this project non-commercial. That means that we will live from donations. If you like what you see, please press the donate button below.</p>
          <hr>
          <b-row>
            <b-col  class="text-left">
              <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
            </b-col>
            <b-col  class="text-right">
              <b-button v-on:click="onDonate()" class="w-button-green">donate</b-button>
            </b-col>
          </b-row>
          <br>
        </b-col>
      </b-row> 
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        isLoading : true,
      }
    }, 
    mounted() {
      this.isLoading = false
    },
    methods: {
      onBack() {
        this.$router.push({path:'/'})
      },
      onDonate() {
        window.open('https://www.paypal.com/donate/?hosted_button_id=968LLYKG8YBP6');
      },
    }
  }
</script>
<style scoped>
.main-title {
  font-size: clamp(24px,5vw, 36px)!important;
  color:rgb(109,122,82) !important;
  text-align:center;
  margin:0;
}

.main-subtitle {
font-size:clamp(14px,2vw, 24px)!important;
color:rgb(112,112,112);
text-align:center;
margin-top:-5px;
font-weight: bold;
}
  .w-title {
    color:rgb(112,112,112);
    font-size:clamp(24px,2vw,36px);
  }
  .w-subtitle {
   color:rgb(112,112,112);
    font-size:clamp(20px,1.2vw,24px);
  }
  .w-quote {
    color:rgb(112,112,112);
    font-size:clamp(16px,1.6vw,18px);
    font-style: oblique;
  }
  .w-text {
    color:rgb(112,112,112);
    font-size:clamp(14px,1.2vw,16px)
  }

  .w-button{
      background-color: rgb(234,202,81) !important;
      border-color: rgb(234,202,81) !important;
      color: #696969 !important;
      font-size: clamp(12px,1.2vw, 16px) !important;
      font-weight: bold !important;
      width: clamp(60px,10vw,160px) !important;
      padding-left: 2px !important;
      padding-right: 2px !important;
  }
</style> 