import Vue from 'vue'
import Router from 'vue-router'

import Index from '@/components/Index'
import Home from '@/components/Home'
import WyneBassadors from '@/components/WyneBassadors'
import WyneMaker from '@/components/WyneMaker'
import WyneCellar from '@/components/WyneCellar'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/Home',
            name: 'Home',
            component: Home
        },
        {
            path: '/WyneBassadors',
            name: 'WyneBassadors',
            component: WyneBassadors
        },
        {
            path: '/admin_wynemaker',
            name: 'WyneMaker',
            component: WyneMaker
        },
        {
            path: '/admin_wynecellar',
            name: 'WyneCellar',
            component: WyneCellar
        },
    ],
    mode: 'history'
})