<template>
  <div class="full">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <p class="w-title text-center">WyneBassadors</p>
      <b-row class="justify-content-center">
        <b-card-group deck>
          <b-card class="w-card">
            <b-card-header>
              <b-avatar src="https://placekitten.com/300/300" size="12rem"></b-avatar>
            </b-card-header>
            <b-card-body>
              <h2 class="w-title text-center">Luc Dooms</h2>
              <hr>
              <p class="w-text text-center"> General Coordination </p>
              <p class="w-text text-center"> Front-End Development </p>
              <p class="w-text text-center"> Back-End Development </p>
            </b-card-body>
          </b-card>
           <b-card class="w-card">
            <b-card-header>
              <b-avatar src="https://placekitten.com/300/300" size="12rem"></b-avatar>
            </b-card-header>
            <b-card-body>
              <h2 class="w-title text-center">Aurang Said</h2>
              <hr>
              <p class="w-text text-center"> Graphical Design </p>
              <p class="w-text text-center"> Front-End UI/UX </p>
              <p class="w-text text-center"> </p>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-row>
      <br>
      <b-row>
        <b-col class="text-center">
          <b-button v-on:click="onBack()" class="wm-button">Back</b-button>
        </b-col>
      </b-row>
      <br>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WyneBassadors',
    data() {
      return {
        isLoading : true,
      }
    }, 
    mounted() {
      this.isLoading = false
    },
    methods: {
      onBack(){
        this.$router.push({path:'/Home'})
      },
    }
  }
</script>
<style scoped>
  .full {
      height:100% !important;
      width:100% !important;
      
      /* background-image: url("../assets/background-7.jpg"); */
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      /* background-color: rgb(0, 93, 148,0.8); */
  }
  .btn-xl { 
      width: 9vw; 
      height: 4vw;
      margin: 1.5vw; 
      border-style: none;
      font-size: 1.3vw; 
      text-align: center;
      font-weight: bold;
      /* color : grey;
      background-color: rgb(234,202,81) !important; */
      color :white;
      background-color: grey !important; 
      border-color: lightgrey !important; 
  } 
  .w-title {
    color:white;
    font-size:clamp(24px,3vw, 24px);
    background-color: rgb(0, 93, 148,0.8);
  }
  .w-subtitle {
    color:white;
    font-size:clamp(16px,1.4vw,24px)
  }
  .w-quote {
    color:white;
    font-size:clamp(16px,1.6vw,24px);
    font-style: oblique;
  }
  .w-text {
    color:white;
    font-size:clamp(14px,1.2vw,14px);
    margin:0
  }

  .w-button{
      background-color: rgb(234,202,81) !important;
      border-color: rgb(234,202,81) !important;
      color: #696969 !important;
      font-size: clamp(12px,1.2vw, 16px) !important;
      font-weight: bold !important;
      width: clamp(60px,10vw,160px) !important;
      padding-left: 2px !important;
      padding-right: 2px !important;
  }

  .wm-button{
  background-color: rgb(218,179,0) !important;
  border-color: rgb(218,179,0)  !important;
  color: rgb(0, 93, 148) !important;
  font-size: clamp(14px,1.2vw, 16px) !important;
  font-weight: bold !important;
  width: clamp(120px,10vw,160px) !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  /* position: absolute; */
}

  .w-card {
    background-color: rgb(0, 93, 148) !important;
  }
.text-left{ /* important to keep the w-buttons close*/
  padding:0 !important;
}
.text-center{ /* important to keep the w-buttons close*/
  padding:0 !important;;
}
.text-right{ /* important to keep the w-buttons close*/
  padding:0 !important;;
}
</style> 