import Vue from 'vue'
import App from './App'
import router from './router'

import BootstrapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueAnalytics from 'vue-analytics';

import { store } from './store/store.js'

Vue.use(BootstrapVue)

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'UA-220960889-4',
  router,
});

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')