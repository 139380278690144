<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row class="justify-content-center">
          <p class="w-title">an overview of WyneMaker subscripions</p>
      </b-row>
       <b-row class="justify-content-center">
          <p class="w-title">{{wineries.length}}</p>
      </b-row>
      <hr>
      <b-row
        v-for="(winery, index) in wineries"
        v-bind:item="winery"
        v-bind:index="index"
        v-bind:key="winery._id"
        class="justify-content-center"
        style="display:block" 
      >
        <p class="wm-title">{{winery.name}}</p>
        <p class="wm-title">{{winery.number}} wines</p>
        <p class="wm-text">{{winery.firstname}} {{winery.lastname}}</p>
        <p class="wm-text">{{winery.email}}</p>
        <p class="wm-text w-cursor-click" v-if="winery.webname" v-on:click="gotoWebsite(winery.webname)"> <b>http://www.wynemaker.com/{{winery.webname}}</b></p>
        <hr>
      </b-row>

    </div>
  </div>
</template>

<script>
  import Wineries from '../Wineries.js'
  import Users from '../Users.js'

  export default {
    name: 'Home',
    data() {
      return {
        debug: false,
        isLoading: true,
        wineries: [],
      }
    }, 
    async mounted() {
      await Wineries.get()
      .then(response => {
        for ( let i=0 ; i < response.length ; i++ ) {
          this.wineries.push(response[i])
        }
        if (this.debug) console.log( "WyneMaker.mounted: ",this.wineries.length," wineries succesfully fetched from DB");
      })
      .catch(error => {
        if (this.debug) console.log( "WyneMaker.mounted: catch Wineries.get with ERROR - ",error);
      })

      for ( let i=0 ; i < this.wineries.length ; i++ ) {
        if (typeof this.wineries[i].user_id != "undefined") {
          await Users.getone(this.wineries[i].user_id)
          .then(response => {
            if ( typeof response == "object" ) {
              this.wineries[i].firstname = response.firstname
              this.wineries[i].lastname = response.lastname
              this.wineries[i].email = response.email
              if (typeof this.wineries[i].wine_ids != "undefined") {
                this.wineries[i].number = this.wineries[i].wine_ids.length
              } else {
                this.wineries[i].number = 0
              }
              if (this.debug) console.log( "WyneMaker.mounted: winery with id = ",this.wineries[i]._id, " has user = ",response , " SUCCESS")
            } else {
              if (this.debug) console.log( "WyneMaker.mounted: winery with id = ",this.wineries[i]._id, " has user = ",response, " ERROR")
              this.wineries[i].number = 0
            }
          })
          .catch(error => {
            if (this.debug) console.log( "WyneMaker.mounted: catch Users.getone with ERROR - ",error);
          })
        } else {
          if (this.debug) console.log( "WyneMaker.mounted: winery with id = ", wineries[i]._id, " and name = ",wineries[i].name, " has no associated user.");
        }
        
      }

      this.isLoading = false
    },
    methods: {
      gotoWebsite(webname) {
        window.open('http://www.wynemaker.com/'+ webname, '_blank');
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.wm-title {
  font-size: clamp(14px,3vw, 18px)!important;
  color:grey !important;
  text-align:center;
  margin:0;
}
.wm-text {
  font-size: clamp(10px,2.5vw, 12px)!important;
  color:grey !important;
  text-align:center;
  margin:0;
}
</style>