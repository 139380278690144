<template>
  <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
    <IndexMobile  v-if="matches" />
    <IndexDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import IndexMobile from "./Index_Mobile.vue";
  import IndexDesktop from "./Index_Desktop.vue";
 
  export default {
    name: 'Index',
    components: {
      MatchMedia,
      IndexMobile,
      IndexDesktop,
    },
  }
</script> 