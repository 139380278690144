<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row class="justify-content-center">
          <p class="w-title">an overview of WyneCellar subscripions</p>
      </b-row>
       <b-row class="justify-content-center">
          <p class="w-title">{{cellars.length}}</p>
      </b-row>
      <hr>
      <b-row
        v-for="(cellar, index) in cellars"
        v-bind:item="cellar"
        v-bind:index="index"
        v-bind:key="cellar._id"
        class="justify-content-center"
        style="display:block" 
      >
        <p class="wm-title">{{cellar.name}}</p>
        <p class="wm-title">{{cellar.number}} bottles</p>
        <p class="wm-title">{{cellar.number_of_niches}} niches</p>
        <p class="wm-text">{{cellar.firstname}} {{cellar.lastname}}</p>
        <p class="wm-text">{{cellar.email}}</p>
        <hr>
      </b-row>

    </div>
  </div>
</template>

<script>
  import Cellars from '../Cellars.js'
  import Niches from '../Niches.js'
  import Entries from '../Entries.js'
  import Users from '../Users.js'

  export default {
    name: 'Home',
    data() {
      return {
        debug: false,
        isLoading: true,
        cellars: [],
      }
    }, 
    async mounted() {
      await Cellars.get()
      .then(response => {
        for ( let i=0 ; i < response.length ; i++ ) {
          this.cellars.push(response[i])
        }
        if (this.debug) console.log( "WyneCellar.mounted: ",this.cellars.length," cellars succesfully fetched from DB");
      })
      .catch(error => {
        if (this.debug) console.log( "WyneCellar.mounted: catch Cellars.get with ERROR - ",error);
      })

      for ( let i=0 ; i < this.cellars.length ; i++ ) {
        if (typeof this.cellars[i].user_id != "undefined") {
          await Users.getone(this.cellars[i].user_id)
          .then(response => {
            if ( typeof response == "object" ) {
              this.cellars[i].firstname = response.firstname
              this.cellars[i].lastname = response.lastname
              this.cellars[i].email = response.email
              if (this.debug) console.log( "WyneCellar.mounted: cellar with id = ",this.cellars[i]._id, " has user = ",response , " SUCCESS")
            } else {
              if (this.debug) console.log( "WyneCellar.mounted: cellar with id = ",this.cellars[i]._id, " has user = ",response, " ERROR")
              this.cellars[i].number = 0
            }
          })
          .catch(error => {
            if (this.debug) console.log( "WyneCellar.mounted: catch Users.getone with ERROR - ",error);
          })
        } else {
          if (this.debug) console.log( "WyneCellar.mounted: cellar with id = ", cellars[i]._id, " and name = ",cellars[i].name, " has no associated user.");
        }
        
        if (typeof this.cellars[i].entry_ids != "undefined") {
          this.cellars[i].number = 0
          for (let j=0 ; j < this.cellars[i].entry_ids.length ; j++) {
            await Entries.getone(this.cellars[i].entry_ids[j])
            .then(response => {
              if ( typeof response == "object" ) {
                this.cellars[i].number = this.cellars[i].number + response.number
              } else {
                if (this.debug) console.log( "WyneCellar.mounted: cellar with id = ",this.cellars[i]._id, " has entry_ids = ",response, " ERROR")               
              }
            })
            .catch(error => {
              if (this.debug) console.log( "WyneCellar.mounted: catch Entries.getone with ERROR - ",error);
            })
          }
        }

        if (typeof this.cellars[i].niche_ids != "undefined") {
          this.cellars[i].number_of_niches = 0
          for (let j=0 ; j < this.cellars[i].niche_ids.length ; j++) {
            await Niches.getone(this.cellars[i].niche_ids[j])
            .then(response => {
              if ( typeof response == "object" ) {
                this.cellars[i].number_of_niches = this.cellars[i].number_of_niches + 1
              } else {
                if (this.debug) console.log( "WyneCellar.mounted: niche with id = ",this.cellars[i]._id, " has niche_ids = ",response, " ERROR")               
              }
            })
            .catch(error => {
              if (this.debug) console.log( "WyneCellar.mounted: catch Niches.getone with ERROR - ",error);
            })
          }
        }
      }

      this.isLoading = false
    },
    methods: {
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.wm-title {
  font-size: clamp(14px,3vw, 18px)!important;
  color:grey !important;
  text-align:center;
  margin:0;
}
.wm-text {
  font-size: clamp(10px,2.5vw, 12px)!important;
  color:grey !important;
  text-align:center;
  margin:0;
}
</style>