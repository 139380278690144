<template>
  <div v-if="this.isLoading" class="title-bar">
    <b-form>
      <br><br><br><br><br>
      <h5 style='color:white'>loading</h5>
      <br>
       <b-spinner style="color: rgb(109,122,82) !important"
        variant="warning"
      ></b-spinner>
    </b-form>
  </div>
  <div v-else class="main">
    <b-row style="margin:0">
      <b-col md="8" style="height:100vh;padding-left:0!important">
        <div class="login-picture">
          <div class="photography">
            <p>photography : tim mossholder</p>
          </div>
          <div class="login-overlay">
            <h1 class="main-title"><b><span style="color: rgb(195,147,130)">WYNE</span></b>LOVER</h1>
            <p class="main-subtitle">an open digital wine experience initiative</p>
            <br>
          </div>
          <div class="social-media">
            <div style="width:30px; display:inline-block; margin-right:10px">
              <b-img
                fluid
                :src="require('@/assets/img/facebook-white.png')"
                v-on:click="gotoFacebook()"
              >
              </b-img>
            </div>
            <div style="width:30px; display:inline-block;margin-left:10px">
              <b-img
                fluid
                :src="require('@/assets/img/instagram-white.png')"
                v-on:click="gotoInstagram()"
              >
              </b-img>
            </div>
          </div>
          <div class="copyright">
            <p style="text-align:center"> funware for wine lovers developed and ©copyrighted by N'JOY Information Technology</p>
          </div>  
        </div>
      </b-col>
      <b-col md="4" style="border-left: 5px solid white">
        <b-row class="click-box w-cursor-click" v-on:click="onHome()">
          <div class="home-picture">
            <div class="box-overlay">
              <p class="box-text">who we are, what we do</p>
            </div>
          </div>
        </b-row>
        <b-row class="click-box w-cursor-click" v-on:click="onWyneMaker()">
          <div class="wynemaker-picture">
            <div class="box-overlay">
              <p class="box-text">for professional wine makers</p>
            </div>
          </div>
        </b-row>
        <b-row class="click-box w-cursor-click" v-on:click="onWyneCellar()">
          <div class="wynecellar-picture">
            <div class="box-overlay">
              <p class="box-text">for wine lovers with a wine cellar</p>
            </div>
          </div>
        </b-row>
        <b-row class="click-box w-cursor-click" v-on:click="onWyneTaster()">
          <div class="wynetaster-picture">
            <div class="box-overlay">
              <p class="box-text">for wine tasting organisers and participants</p>
            </div>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'Index',
    data() {
      return {
        isLoading : true,
      }
    }, 
    mounted() {
      this.isLoading = false;
    },
    methods: { 
      onHome() {
        this.$router.push({path:'/Home'});
      },
      onWyneMaker() {
       window.open('http://www.wynemaker.com', '_blank');
      },
      onWyneCellar() {
       window.open('http://www.wynecellar.com', '_blank');
      },
      onWyneTaster() {
       window.open('http://www.wynetaster.com', '_blank');
      },
      gotoFacebook() {
        window.open("https://www.facebook.com/wynelover.eu", '_blank');
      },
      gotoInstagram() {
        window.open("https://www.instagram.com/wyne.lover/", '_blank');
      },      
    },
  }
</script>

<style scoped>
.main {
  overflow-x: hidden;
  overflow-y: hidden;
}
.login-picture {
    height:100vh !important;
    width:100% !important;
    
    background-image: url("../assets/img/wynelover.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:absolute;
    display: flex;
    justify-content: center;
}
.home-picture {
    height:25vh !important;
    width:100% !important;
    
    background-image: url("../assets/img/home.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:absolute;
    display: flex;
    justify-content: center;
    border-bottom: 5px white solid;
}

.wynemaker-picture {
    height:25vh !important;
    width:100% !important;
    
    background-image: url("../assets/img/wynemaker.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:absolute;
    display: flex;
    justify-content: center;

    border-bottom: 5px white solid;
}

.wynecellar-picture {
    height:25vh !important;
    width:100% !important;
    
    background-image: url("../assets/img/wynecellar.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:absolute;
    display: flex;
    justify-content: center;

    border-bottom: 5px white solid;
}

.wynetaster-picture {
    height:25vh !important;
    width:100% !important;
    
    background-image: url("../assets/img/wynetaster.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:absolute;
    display: flex;
    justify-content: center;
}

.login-overlay {
  position: relative;
  top: 35vh;
}

.box-overlay {
  position:relative;
  top:45%;
}


.main-title {
  font-size: clamp(54px,5vw, 96px)!important;
  color:white !important;
  text-align:center;
  margin:0;
}

.main-subtitle {
font-size:clamp(14px,2vw, 24px)!important;
color:rgb(200,200,200);
text-align:center;
margin-top:-5px;
font-weight: bold;
}


.box-text {
font-size:clamp(14px,2vw, 24px)!important;
color:white;
text-align:center;
margin-top:-5px;
font-weight: bold;
}

.click-box {
  border-style: solid;
  border-width: 10px;
  border-color:white;
  height:25%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.social-media {
  position: fixed;
  bottom: 55px;
  color: white;
}
.copyright {
  position: fixed;
  font-size:clamp(8px,1.5vw, 14px)!important;
  bottom: 0px;
  color: white;
}

.title-bar {
  text-align: center;
}

.w-cursor-click {
  cursor: pointer;
}



</style>
