import { API } from './API.js';
import axios from 'axios';

const url = API + '/users/';
const debug = false;

class Users {
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Users.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('Users.getone: ',error)
            })
    }
    static create(postdata) {
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Users.create: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('Users.create :',error)
            })
    }
    static update(id,postdata) {
        if (debug) console.log('Users.update: id=',id)
        if (debug) console.log('Users.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('User.update: ',response)
                return id
            })
            .catch(error => {
                console.log('User.update : ',error)
            })
    }
    static delete(id) {
        return axios.delete(`${url}${id}`)
    }
}

export default Users